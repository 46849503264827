import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ui-img-dialog',
  templateUrl: './img-dialog.component.html',
  styleUrls: ['./img-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgDialogComponent implements OnInit {

  @ViewChild('image', { static: true }) image!: ElementRef<HTMLImageElement>

  constructor(
    public dialogRef: MatDialogRef<ImgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImgDialogData) {

  }

  ngOnInit(): void {
    const nativeElement = this.image.nativeElement;
    if (this.data.jaExisteImagem) {
      this.data.arquivo.text().then(value => nativeElement.src = value);
    } else
      nativeElement.src = URL.createObjectURL(this.data.arquivo);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


class ImgDialogData {
  nome: string = '';
  arquivo!: Blob;
  jaExisteImagem: boolean = false;
}
