<div class="wrapper w-100 {{formGroup.invalid && (formGroup.dirty || formGroup.touched) ? 'erro' : ''}}"
     [hidden]="!isVisible">
  <ui-hidden-field *ngIf="field.type === 'Hidden'"
                   class="w-100"
                   [config]="field"
                   [formGroup]="formGroup">
  </ui-hidden-field>
  <ui-date-field *ngIf="field.type === 'Date'"
                 class="w-100"
                 [config]="field"
                 [formGroup]="formGroup"></ui-date-field>

  <ui-text-field *ngIf="field.type === 'Text'"
                 class="w-100"
                 [config]="field"
                 [formGroup]="formGroup">
  </ui-text-field>
  <ui-checkbox-field *ngIf="field.type === 'Checkbox'"
                     class="w-100"
                     [config]="field"
                     [formGroup]="formGroup">
  </ui-checkbox-field>
  <ui-enum-field *ngIf="field.type === 'Enum'"
                 class="w-100"
                 [config]="field"
                 [formGroup]="formGroup">
  </ui-enum-field>
  <ui-file-field *ngIf="field.type === 'File'"
                     class="w-100"
                     [config]="field"
                     [formGroup]="formGroup">
  </ui-file-field>
  <ui-img-file-field *ngIf="field.type === 'ImgFile'"
                     class="w-100"
                     [config]="field"
                     [formGroup]="formGroup">
  </ui-img-file-field>
  <ui-auto-complete-field *ngIf="field.type === 'Autocomplete'"
                          class="w-100"
                          [config]="field"
                          [formGroup]="formGroup">
  </ui-auto-complete-field>
  <ui-select-field *ngIf="field.type === 'Select'"
                   class="w-100"
                   [config]="field"
                   [formGroup]="formGroup">
  </ui-select-field>
  <div class="error pt-0"
       *ngIf="formGroup.invalid && (formGroup.dirty || formGroup.touched) ">
    <small class="mat-error" *ngFor="let error of errors">{{validatorMessage(error)}}</small>
  </div>
</div>
