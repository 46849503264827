import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit, ViewChild
} from '@angular/core';
import {FocusMonitor} from '@angular/cdk/a11y';
import {BaseFieldDirective} from '../base-field.directive';
import {AutoCompleteField} from './AutoCompleteField';
import {of} from 'rxjs';
import {catchError, debounceTime, filter, startWith, switchMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ApiResult, ListaPaginada, Meta} from '../../../services';
import {FormControl, FormGroup} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {AutoCompleteService} from './auto-complete.service';

@Component({
    selector: 'ui-auto-complete-field',
    templateUrl: './auto-complete-field.component.html',
    styleUrls: ['./auto-complete-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoCompleteFieldComponent extends BaseFieldDirective
    implements OnDestroy, OnInit, AfterViewInit {

    @Input() config!: AutoCompleteField;
    @ViewChild('searchInput', {static: false}) searchInput: ElementRef<HTMLInputElement>;
    values: Array<{ id: any; nome: string }> = [];
    selecionado?: {id: any; nome: string};
    meta!: Meta;
    carregando = true;

    constructor(
        @Inject(AutoCompleteService) protected service: AutoCompleteService,
        protected changeDetector: ChangeDetectorRef,
        protected httpClient: HttpClient,
        protected fmRef: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>
    ) {
        super(fmRef, elRef);
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.forEach(x => x?.unsubscribe());
        }
        super.ngOnDestroy();
    }

    getForm(): FormControl {
        const form = this.formGroup!.get(this.config.property)!;
        return (this.config.usaValueObject ?  form.get('nome') : form) as FormControl;
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    ngOnInit() {
        super.ngOnInit();

        const fc = this.getForm()!;
        this.subscriptions.push(
            fc.valueChanges
                .pipe(startWith(''),
                    debounceTime(500),
                    filter(()=> fc.touched || fc.dirty),
                    filter((valor) => valor !== this.selecionado?.nome),
                    tap(() => this.config.usaValueObject && this.controle.get('id')!.setValue('')),
                    switchMap(this.getItens))
                .subscribe());

        if(this.controle.value){
            this.selecionado = {
                id: this.controle.value,
                nome: ''
            };

            if (this.config.usaValueObject && this.controle.get('id').value) {
                this.selecionado = this.controle.value;
            }else{
                this.selecionado = null;
            }
        }

        if (this.config.disabled) {
            this.controle.disable();
        }
    }

    public atualizaControle = (event: MatAutocompleteSelectedEvent) => {
        this.selecionado = {
            id: event.option.value,
            nome: ''
        };

        if (this.config.usaValueObject) {
            this.selecionado = event.option.value;
        }

        this.controle.patchValue(event.option.value);

        if(this.config.onValueSelectedFunc){
            this.config.onValueSelectedFunc(event.option.value);
        }

        this.searchInput.nativeElement.value = '';
        this.values = [];
    };

    public getValue = () => {
        let valor = this.selecionado && this.config.usaValueObject ? this.selecionado.nome : this.selecionado.id;
        return valor.length > 50 ? `${valor.substr(0,50)}...` : valor;
    }

    private getItens = (filtro: any) => {
        if (!filtro?.length || filtro?.length < 3) {
            this.carregando = false;
            this.values = [];
            return of([]);
        }

        const filtroAutoComplete = this.config.propertySearchFunc ?
            this.config.propertySearchFunc(filtro) :
            `${this.config.propertySearch}.ToLower().Contains("${filtro.toLowerCase()}")`;
        this.carregando = true;
        this.changeDetector.detectChanges();
        return this.getRequest(filtroAutoComplete);
    };

    private getRequest = (filtroAutoComplete: any) => this.httpClient.get<ApiResult<ListaPaginada<any>>>(
        this.montaUrl(filtroAutoComplete))
        .pipe(tap(({ data }) => {

            this.meta = { pagina: data!.pagina, total: data!.total } as Meta;

            if (this.config.usaValueObject && !data!.total) {
                this.controle.get('id')!.reset();
            }

            this.controle.updateValueAndValidity();

            this.values = data!.itens.map(item => this.mapToAutocompleteItem(item));
            this.carregando = false;
            this.changeDetector.detectChanges();
        }), catchError((error, source) => {
            this.carregando = false;
            return of([]);
        }));

    private montaUrl = (filtro: string) => `${this.config.url || this.service.apiUrl}${this.config.endpoint}?filtro=${filtro}`;

    private mapToAutocompleteItem = (item: any) =>
        ({
            nome: this.config.entityTextFunc ? this.config.entityTextFunc(item) : item[this.config.entityText],
            id: item[this.config.entityValue]
        });

    limpar() {
        this.controle.reset();
        this.selecionado = null;
        this.values = [];
    }

    onBackspace(event: any) {
        if(event.key === 'Backspace') {
            this.values = [];
        }
    }

}
