import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MasterComponent} from 'ui-shared';
import {TreinamentoConfigAuthGuard} from './features/compartilhado/login/treinamento-config-auth.guard';
import {TreinamentoAuthGuard} from './features/compartilhado/login/treinamento-auth.guard';
import {LoginComponent} from './features/compartilhado/login/login.component';

const routes: Routes = [
    {
        path: 'config',
        component: MasterComponent,
        canActivate: [TreinamentoConfigAuthGuard],
        children: [
            {
                path: 'home',
                loadChildren: () => import('./features/configuracao/home-config/home-config.module')
                    .then(m => m.HomeConfigModule)
            },
            {
                path: 'parametros',
                loadChildren: () => import('./features/configuracao/parametros/parametros.module')
                    .then(m => m.ParametrosModule)
            },
            {
                path: 'parametro-valor',
                loadChildren: () => import('./features/configuracao/parametro-valor/parametro-valor.module')
                    .then(m => m.ParametroValorModule)
            },
            {
                path: 'aula',
                loadChildren: () => import('./features/configuracao/aula/aula.module')
                    .then(m => m.AulaModule)
            },
            {
                path: 'material',
                loadChildren: () => import('./features/configuracao/material/material.module')
                    .then(m => m.MaterialModule)
            },
            { path: '**', redirectTo: 'home' }
        ]
    },
    {
        path: '',
        component: MasterComponent,
        canActivate: [TreinamentoAuthGuard],
        children: [
            {
                path: 'home',
                loadChildren: () => import('./features/operacional/home/home.module')
                    .then(m => m.HomeModule)
            },
            {
                path: 'apresentacao',
                loadChildren: () => import('./features/operacional/apresentacao/apresentacao.module')
                    .then(m => m.ApresentacaoModule)
            },
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full'
    },
    {
        path: '**', redirectTo: '/login'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
