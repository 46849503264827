<mat-sidenav-container class="sidenav-container">
    <mat-sidenav
        #drawer
        class="sidenav"
        fixedInViewport
        attr.role="dialog"
        mode="over"
    >
        <mat-toolbar>Menu</mat-toolbar>
        <mat-nav-list>
            <ng-container *ngFor="let link of masterService.links; last as last">
                <ui-master-item
                    [link]="link"
                    (handleLinkClick)="handleClick($event)"
                ></ui-master-item>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary" id="nav-bar">
            <button
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="drawer.toggle()"
            >
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span>
                <button [routerLink]="masterService.rota" class="button-title" mat-button>
                  {{masterService.titulo}}
                    <img *ngIf="masterService.imgURL" class="ico-title" src="{{masterService.imgURL}}"
                         alt="Blue Route Logo"/>
                </button>
            </span>
            <span class="spacer"></span>
            <ui-notificacao *ngIf="masterService.notificacao"></ui-notificacao>
            <ui-conta-em-uso *ngIf="masterService.multiConta" [contas]="masterService.contas"
                             [permicaoAmbiente]="masterService.permicaoAmbiente" ></ui-conta-em-uso>
            <ui-usuario-logado
                [links]="masterService.usuarioLinks"
                class="d-flex align-content-center my-auto p-2">
            </ui-usuario-logado>
        </mat-toolbar>
        <div class="container-fluid mb-4">
            <ui-loader></ui-loader>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
