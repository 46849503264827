export class Base64Helper {
  public static downloadArquivo = (arquivo: string, nomeArquivo: string, formatoArquivo: string) => {
    var blob = Base64Helper.base64ToBlob(arquivo, formatoArquivo);
    const url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.download = nomeArquivo;
    link.href = url;
    link.click();
  }

  public static base64ToBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    b64Data = b64Data.replace(/\s/g, '');
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
