import {API_URL, CrudService, ApiResult} from 'ui-shared';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AulaEntity} from "@treinamento/models";

@Injectable({ providedIn: 'root' })
export class AulaDataService extends CrudService<AulaEntity>
{
    protected url: string;

    constructor(
        @Inject(API_URL) urlBase: string,
        protected httpClient: HttpClient
    )
    {
        super(urlBase, httpClient);
        this.url = `${urlBase}/v1/Aula`;
    }

    public getAulasApresentacoes = () => this.httpClient.get<ApiResult<AulaEntity[]>>(`${this.url}/apresentacao`);
    public getAulaApresentacao = (id: string) => this.httpClient.get<ApiResult<AulaEntity>>(`${this.url}/apresentacao/${id}`);
}
