import {DOCUMENT} from '@angular/common';
import {Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser'
import {LoginService, MasterService, Token} from 'ui-shared';
import {masterConfiguracao, masterOperacao} from './master-config';
import {Subscription} from 'rxjs';
import {AulaDataService} from "@treinamento/services";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
    token: Token;
    subscription: Subscription;
    private carregado: boolean = false;

    constructor(
        public route: ActivatedRoute,
        private router: Router,
        private loginService: LoginService,
        private masterService: MasterService,
        private aulaDataService: AulaDataService,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: Document,
        private title: Title
    ) {
        this.title.setTitle('Treinamento')
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    ngOnInit(): void {
        this.subscription = this.loginService.token$.subscribe(token => {
            if (token) {
                masterOperacao.multiConta = token.suporte;

                this.ngZone.run(() => {
                    this.router.events.subscribe((event) => {
                        if (event instanceof NavigationEnd){
                            if (event.url.indexOf('/config') >= 0 ) {
                                if (!this.carregado) {
                                    this.masterService.update(masterConfiguracao);
                                    this.carregado = true;
                                }
                            } else {
                                if (!this.carregado){
                                    this.masterService.update(masterOperacao);
                                    this.aulaDataService.getAulasApresentacoes().pipe(
                                        map((apiRes) => {
                                            let links = [];
                                            const aulas = apiRes.data;
                                            for (let i = 0; i < aulas.length; i++) {
                                                const aula = aulas[i];
                                                const nome = aula.nome.length > 50 ? `${aula.nome.substr(0,50)}...` : aula.nome
                                                links.push({
                                                    titulo: nome,
                                                    icone: 'play_lesson',
                                                    label: nome,
                                                    rota: `/apresentacao/${aula.id}`
                                                })
                                            }

                                            links = masterOperacao.links.concat(links);
                                            this.masterService.update({...masterOperacao, links});
                                            this.carregado = true;
                                        })
                                    ).subscribe();
                                }
                            }
                        }
                    });
                });
            }
        });
    }
}
