const PortalUnicoAccessToken: string = 'PortalUnicoAccessToken';
const PortalUnicoXcsrfToken: string = 'PortalUnicoXcsrfToken';
const PortalUnicoXcsrfExpiracao: string = 'PortalUnicoXcsrfExpiracao';
const UsuarioLogado: string = 'CatLog.UsuarioLogado';
const Token: string = 'CatLog.Token';
const Permissoes: string = 'CatLog.Permissoes';


export class LocalStorageUtil {

  public static obterUsuario(): string | null{
    return localStorage.getItem(UsuarioLogado);
  };

  public static limparDadosLocaisUsuario() {
    localStorage.removeItem(Token);
    localStorage.removeItem(UsuarioLogado);
  };

  public static obterTokenUsuario(): string | null {
    return localStorage.getItem(Token);
  };

  public static salvarTokenUsuario(token: string) {
    localStorage.setItem(Token, token);
  };

  public static salvarUsuario(user: string) {
    localStorage.setItem(UsuarioLogado, user);
  };

  public static salvarPermissoes(permissoes: string) {
    localStorage.setItem(Permissoes, permissoes);
  }

  public static obterPermissoes(): string {
    return localStorage.getItem(Permissoes);
  };

  public static obterDadosPortalUnico = () => ({
    accessToken: sessionStorage.getItem(PortalUnicoAccessToken),
    xCSRFToken: sessionStorage.getItem(PortalUnicoXcsrfToken),
    xCSRFExpiracao: sessionStorage.getItem(PortalUnicoXcsrfExpiracao)
  });

  public static limparDadosPortalUnico(){
    localStorage.removeItem(PortalUnicoAccessToken);
    localStorage.removeItem(PortalUnicoXcsrfToken);
    localStorage.removeItem(PortalUnicoXcsrfExpiracao);
  };

  public static salvarDadosPortalUnico({ setToken: accessToken, xcsrfToken, xcsrfExpiration: tempoExpiracao }:
                                         { setToken: string; xcsrfToken: string; xcsrfExpiration: string }) {
    sessionStorage.setItem(PortalUnicoAccessToken, accessToken);
    LocalStorageUtil.atualizarXcsrfToken(xcsrfToken, tempoExpiracao);
  };

  public static atualizarXcsrfToken(xcsrfToken: string, xcsrfExpiration: string) {
    sessionStorage.setItem(PortalUnicoXcsrfToken, xcsrfToken);
    sessionStorage.setItem(PortalUnicoXcsrfExpiracao, xcsrfExpiration);
  };

  public static removerDadosPortalUnico = () => {
    sessionStorage.removeItem(PortalUnicoAccessToken);
    sessionStorage.removeItem(PortalUnicoXcsrfToken);
    sessionStorage.removeItem(PortalUnicoXcsrfExpiracao);
  };
}
