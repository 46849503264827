<p *ngIf="!data" class="no-data">
    Carregando...
</p>

<p *ngIf="data && !data.length" class="no-data" [innerHtml]="mensagemSemDados">
</p>
<div class="table-wrapper">
    <table mat-table [dataSource]="data" class="mat-elevation-z1" *ngIf="data?.length" matSort
           (matSortChange)="sortBy($event)">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of props">
            <th mat-header-cell *matHeaderCellDef [disabled]="isAction(column) || column === 'checkbox' || !getPermiteOrdenar(column)"
                [mat-sort-header]="column" [ngClass]="{
                'w-acoes': isAction(column),
                'w-checkbox' : column === 'checkbox'
            }">
                <mat-checkbox *ngIf="column === 'checkbox' && mostrarSelecionarTodos"
                              (change)="selecionarTodosHandler($event.checked)">
                </mat-checkbox>
                <ng-container *ngIf="column !=='checkbox'">
                    {{isAction(column) ? getAcoesTitle() : column}}
                </ng-container>
            </th>

            <td mat-cell *matCellDef="let item" [ngClass]="
            {
            'w-acoes': column === 'Ações',
            'w-checkbox' : column === 'checkbox'
            }">
                <ng-container *ngIf="column !== 'Ações';else botoes">
                    <mat-checkbox *ngIf="column ==='checkbox' && mostrarSelecionarItem"
                                  (change)="emitirSelecionarItem(item, $event.checked)" [checked]="itemSelecionado(item)"></mat-checkbox>
                    <div *ngIf="column !=='checkbox'" [class]="getClasses(item, column)" [title]="getTitle(item,column) || ''">
                        <span *ngIf="hasTooltip(item, column)" class="tooltiptext">{{getToolTip(item, column)}}</span>
                        {{ getMask(item,column) ? (getValue(item,column) | mask: getMask(item,column)) : getValue(item,column) }}
                    </div>
                </ng-container>
                <ng-template #botoes>
                    <button mat-icon-button *ngIf="config.actions?.length === 1" (click)="config.actions[0].action(item)" [disabled]="desabilitar(config.actions[0])">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="config.actions?.length > 1" [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{item: item, config: config}">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="props"></tr>
        <tr mat-row *matRowDef="let row; columns: props;"></tr>
    </table>
    <mat-paginator
        *ngIf="meta?.total"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        [pageSize]="meta!.qtdeRegistros"
        [pageIndex]="meta!.pagina! -1"
        [length]="meta!.total"
        (page)="handleMetaChanged($event)"
        [showFirstLastButtons]="false"></mat-paginator>
</div>
<mat-menu #actionsMenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <div *ngFor="let action of config.actions">
            <div *ngIf="!esconder(item, action)">
                <button mat-menu-item (click)="action.action(item)" [disabled]="desabilitar(action)">
                    <span>{{action.title}} </span>
                </button>
            </div>
        </div>
    </ng-template>
</mat-menu>
