import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component, ChangeDetectionStrategy, Input, ElementRef, ViewChild, ChangeDetectorRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';

import { BaseFieldDirective } from '../base-field.directive';
import { ImgDialogComponent } from './img-dialog/img-dialog.component';
import { ImgFileField } from './img-file-field';

@Component({
  selector: 'ui-img-file-field',
  templateUrl: './img-file-field.component.html',
  styleUrls: ['./img-file-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgFileFieldComponent extends BaseFieldDirective {
  @Input() config!: ImgFileField;
  @ViewChild('image', { static: true }) image!: ElementRef<HTMLImageElement>;
  @ViewChild('fileInput', { static: true }) fileInput!: ElementRef<HTMLInputElement>;
  fileName = '';
  file: any;
  jaExisteImagem: boolean = false;
  constructor(
    public dialog: MatDialog,
    protected fmRef: FocusMonitor,
    protected elRef: ElementRef<HTMLElement>,
    protected changeDetection: ChangeDetectorRef
  ) {
    super(fmRef, elRef);

  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.controle.value) {
      this.jaExisteImagem = true;
      const image = 'data:image/jpeg;base64,' + this.controle.value;
      this.fileName = this.formGroup.get('id')?.value;
      this.file = image;
    }
    this.subscriptions.push(this.controle.valueChanges.pipe(
      tap(value => {
        if (!value) {
          this.fileName = '';
          this.file = null
          this.image.nativeElement.src = '';
          this.changeDetection.detectChanges();
        }
      })
    ).subscribe());
  }

  atualizaSrcImg() {
    if (this.jaExisteImagem) {
      this.image.nativeElement.src = this.file;
      return true;
    }

    this.file = this.fileInput?.nativeElement?.files?.item(0);
    if (this.file) {
      this.image.nativeElement.src = URL.createObjectURL(this.file);
      return true;
    }

    return false;
  }

  onFileChanged(event: any) {
    const [file] = event.target.files;
    if (!file) {
      this.controle.reset();
      return;
    }
    const tipos = ['.jpg', '.jpeg', '.png', '.bmp', '.webp'];
    if (!tipos.find(x => file.name.endsWith(x))) {
      this.controle.setErrors({ invalid: true });
    } else {
      this.fileName = file.name;
      this.file = file;
      this.controle.patchValue(file);
      this.jaExisteImagem = false;
    }
  }
  maximizaImagem() {
    if (!this.file) { return; }
    this.dialog.open(ImgDialogComponent, {
      width: '80vw',
      height: '80vh',
      data: { name: this.fileName, arquivo: this.file, jaExisteImagem: this.jaExisteImagem }
    });
  }
}
