import { BaseField } from '../base-field';

export class DateField<T = any> extends BaseField<T> {
  constructor(init: Partial<DateField<T>>) {
    super('Date', init);
    Object.assign(this, init);
    if (!init.css) {
      this.css = 'col-sm-12 col-md-6 col-lg-4';
    }
  }
}
