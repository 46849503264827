<mat-form-field
  appearance="outline"
  [formGroup]="formGroup"
  style="margin-bottom: -1.25em; width: 100%; position: relative;"
>
  <mat-label>{{config.label}}</mat-label>
  <input
    matInput
    readonly
    class="cursor-pointer"
    [value]="fileName"
    (click)="fileInput.click()"
  />
  <mat-icon
    matSuffix *ngIf="fileName"
    class="cursor-pointer"
    (mouseenter)="atualizaSrcImg(); imageWrapper.style.display = 'block'"
    (mouseleave)="imageWrapper.style.display='none'"
    (click)="maximizaImagem()"
    >{{config.sufixo}}</mat-icon
  >
  <input
    type="file"
    #fileInput
    id="uploadFile"
    (change)="onFileChanged($event)"
    [accept]="['jpeg', 'png', 'jpg', 'webp', 'bmp']"
    [hidden]="true"
  />
</mat-form-field>
<div #imageWrapper class="img-container">
  <img #image />
</div>
