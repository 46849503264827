import {API_URL, CrudService} from 'ui-shared';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ParametroEntity} from '@treinamento/models';

@Injectable({ providedIn: 'root' })
export class ParametrosDataService extends CrudService<ParametroEntity> {

    protected url: string;

    constructor(
        @Inject(API_URL) urlBase: string,
        @Inject(HttpClient) protected httpClient: HttpClient
    ) {
        super(urlBase, httpClient);
        this.url = `${urlBase}/v1/parametro`;
    }
}
