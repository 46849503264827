import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxsStoreModule} from './store/store.module';
import {AppComponent} from './app.component';
import {environment} from 'environments/environment';
import {registerLocaleData} from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import {
    API_URL,
    AutoCompleteService,
    ErrorHttpInterceptor,
    FormModule,
    GridModule,
    IDENTIDADE_API_URL,
    JWTInterceptor,
    LoaderInterceptor,
    MasterModule,
    matFormField,
    matSnackbar,
    OPERACAO_API_URL
} from 'ui-shared';
import {MatDialogModule} from '@angular/material/dialog';
import {LoginComponent} from './features/compartilhado/login/login.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from "@angular/material/icon";
import {
    PaginaNaoEncontradaComponent
} from './features/compartilhado/pagina-nao-encontrada/pagina-nao-encontrada.component';

registerLocaleData(ptBr);

@NgModule({
    declarations: [
        AppComponent, LoginComponent, PaginaNaoEncontradaComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxsStoreModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MasterModule,
        FormModule,
        GridModule,
        MatDialogModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule
    ],
    providers: [
        { provide: API_URL, useValue: environment.treinamento.apiUrl, multi: true },
        { provide: IDENTIDADE_API_URL, useValue: environment.identidade.apiUrl, multi: true },
        { provide: OPERACAO_API_URL, useValue: environment.treinamento.apiUrl, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: matSnackbar() },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormField() },
        { provide: AutoCompleteService, useValue: environment.treinamento},
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
