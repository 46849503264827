import { BaseField } from '../base-field';

export class AutoCompleteField<T = any> extends BaseField<T> {
  public endpoint: string = '';
  public entityText: string = '';
  public entityTextFunc!: (value: any) => string;
  public entityValue: string = '';
  public propertySearch: string = '';
  public propertySearchFunc!: (value: string) => string;
  public usaValueObject: boolean = false;
  public url: string = '';
  public onValueSelectedFunc!: (value: any) => any;

  constructor(init: Partial<AutoCompleteField<T>>) {
    super('Autocomplete', init);
    Object.assign(this, init);
    if (!init.css) {
      this.css = 'col-sm-12 col-md-6 col-lg-4';
    }
  }
}
