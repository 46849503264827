import {Component} from "@angular/core";
import {NotificacaoService} from "./notificacao.service";

@Component({
  selector: 'ui-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.scss'],
})

export class NotificacaoComponent {

  constructor( public service: NotificacaoService ) {
    this.service.obterPendentes();
  }

  public obterMensagemTipo(tipo: string){
    switch (tipo) {
      case TipoNotificacao.Produto:
        return "Existem Produtos que sofreram mudanças nos Atributos da RFB";
      default:
        break;
    }
    return;
  }
}

export enum TipoNotificacao {
  Produto = "Produto",
}
