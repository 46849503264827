import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, } from '@angular/forms';

import { BaseField } from '../fields/base-field';

@Component({
  selector: 'ui-form-field-renderer',
  templateUrl: './form-field-renderer.component.html',
  styleUrls: ['./form-field-renderer.component.scss'],
})
export class FormFieldRendererComponent implements OnInit, OnChanges {

  public get controle(): FormControl {
    const formControl = this.formGroup!.get(this.field!.property) as FormControl;
    return formControl;
  }
  public get errors() {
    if (this.controle.errors) {
      const values = Object.keys(this.controle.errors);
      return values;
    }

    return [];
  }

  @Input() field!: BaseField | any;
  @Input() public entity!: any;
  @Input() formGroup!: FormGroup;
  @Input() item: any;
  @Input() isVisible = true;
  @Input() condicional?: (f: FormGroup | undefined) => void;
  @Input() set disabled(val: any) {
    val ? this.controle.enable() : this.controle.disable();
  }

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.condicional && this.condicional(this.formGroup);
  }

  ngOnInit(): void {

  }

  validatorMessage(error: string): string {
    const campo = this.field?.label;
    if (this.controle.errors?.['required']) {
      return `${campo} é obrigatório`;
    }
    if (this.controle.errors?.['minlength']) {
      const { requiredLength, actualLength } = this.controle.errors?.['minlength'];
      return `${campo} deve ter no mínimo ${requiredLength} caracteres, atual ${actualLength}`;
    }
    if (this.controle.errors?.['maxlength']) {
    }

    if (!this.controle.errors?.['pattern']) {
    }

    if (this.controle.errors?.['min']) {
      const { min, actual } = this.controle.errors?.['min'];
      return `${campo} deve ter no mínimo ${min} caracteres, atual ${actual}`;
    }
    if (this.controle.errors?.['max']) {
      const { max, actual } = this.controle.errors?.['max'];
      return `${campo} deve ter no máximo ${max} caracteres, atual ${actual}`;
    }

    return '';
  }
}
