import {InjectionToken} from '@angular/core';
import {Link} from '../models';
import {ContaEmUsoModel} from "../conta-em-uso";

export class MasterConfig {

    links: Link[];
    usuarioLinks: Link[];
    titulo: string;
    rota: string;
    imgUrl: string;
    notificacao: boolean;
    multiConta: boolean;
    permicaoAmbiente: string;
    contas: ContaEmUsoModel[];

    constructor() {
        this.titulo = 'Aplicação';
        this.rota = '/home';
        this.imgUrl = '';
        this.contas = [];
        this.links = [
            { titulo: 'Home', icone: 'home', label: 'Home', rota: '/' }
        ];
        this.notificacao = false;
        this.multiConta = false;
        this.permicaoAmbiente = 'Producao';
        this.contas = [];
        this.usuarioLinks = [];
    }
}

export const MASTER_OPTIONS_FACTORY = () => new MasterConfig();

export const MASTER_DEFAULT_OPTIONS =
    new InjectionToken<MasterConfig>('master-default-options', {
        providedIn: 'root',
        factory: MASTER_OPTIONS_FACTORY,
    });
