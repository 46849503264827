import {ContaEmUsoModel, MasterConfig} from 'ui-shared';

let contas: ContaEmUsoModel[] = [
    { id: '1', nome: 'Configuração', rota: '/config/home' },
    { id: '2', nome: 'Apresentação', rota: '/home' },
]

export const masterConfiguracao = {
    multiConta: true,
    contas,
    titulo: 'Treinamento - Configuração',
    rota: '/config/home',
    imgUrl: '../assets/AF_ICONE_BLUE_ROUTE_WHITE.png',
    links: [
        { titulo: 'Home', icone: 'home', label: 'Home', rota: 'home' },
        {
            titulo: 'Configuracoes', icone: 'settings', label: 'Configurações', links: [
                { titulo: 'Parâmetros', icone: 'tune', label: 'Parâmetros', rota: 'parametro-valor' }
            ], hide: true
        },
        {
            titulo: 'Cadastro', icone: 'source', label: 'Cadastro', links: [
                { hide: true, titulo: 'Parâmetros', icone: 'settings', label: 'Parâmetros', rota: 'parametros'},
                { titulo: 'Aulas', icone: 'movie_creation', label: 'Aulas', rota: 'aula'},
                { titulo: 'Materiais', icone: 'library_books', label: 'Materiais', rota: 'material'},
            ]
        },
    ],
} as MasterConfig;

export const masterOperacao = {
    multiConta: true,
    contas,
    titulo: 'Treinamento',
    rota: '/home',
    imgUrl: '../assets/AF_ICONE_BLUE_ROUTE_WHITE.png',
    links: [
        {
            titulo: 'Home', icone: 'home', label: 'Home', rota: '/home'
        },
    ]
} as MasterConfig;

