import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from 'ui-shared';

@Injectable({
  providedIn: 'root'
})
export class TreinamentoConfigAuthGuard implements CanActivate {
  constructor(
    @Inject(Router) private router: Router,
    @Inject(LoginService) private loginService: LoginService
  ) { }

  canActivate = () => (this.loginService.estaLogado() && this.loginService.suporte) || this.router.navigate(['home']);

}
