import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigForm } from './config-form'; import { AutoCompleteField } from './fields';

@Component({
  selector: 'ui-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent<T> implements OnInit {

  @Input() public config!: ConfigForm;
  @Input() public btnText: string = '';
  @Input() public disableBtn: boolean = true;
  @Input() public disabled: boolean = false;
  @Input() public entity!: T;
  @Input() public showBtn = true;

  @Output() submitEmitter = new EventEmitter<any>();

  @Input() formGroup: FormGroup = new FormGroup({});
  @Output() formGroupChanged = new EventEmitter<FormGroup>();

  constructor(private formBuilder: FormBuilder, private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
    const group: {[p: string]: any} = {};

    this.config.fields.forEach((field) => {
      if (field.type === 'Autocomplete') {
        group[field.property] = this.trataAutoComplete(field as AutoCompleteField);
      } else {
        group[field.property] = this.trataPadrao(field.validations, field.value);
      }
    });
    this.formGroup = this.formBuilder.group(group, { updateOn: 'change' });
    this.formGroupChanged.emit(this.formGroup);
    if (this.entity) {
      this.formGroup.patchValue(this.entity);
    }

    if (this.disabled) {
      this.formGroup.disable();
    }
  }

  submitForm() {
    if (!this.disableBtn || (this.formGroup.valid && !this.formGroup.disabled && (this.formGroup.touched || this.formGroup.dirty))) {
      this.submitEmitter.emit(this.formGroup);
    }
  }

  private trataAutoComplete(field: AutoCompleteField) {
    if (field.usaValueObject) {
      return new FormGroup({
        id: this.trataPadrao(field.validations, field.value),
        nome: this.trataPadrao(field.validations, field.value),
      });
    }
    return this.trataPadrao(field.validations, field.value);
  }

  private trataPadrao(validations: any, value: any) {
    const val = value ? value(this.entity) : null;
    return new FormControl(val, {
      validators: validations,
      updateOn: 'change'
    });
  }
}
