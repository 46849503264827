import {API_URL, ApiResult, CrudService} from 'ui-shared';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ParametroValorEntity} from '@treinamento/models';

@Injectable({ providedIn: 'root' })
export class ParametroValorDataService extends CrudService<ParametroValorEntity> {

    protected url: string;

    constructor(
        @Inject(API_URL) urlBase: string,
        @Inject(HttpClient) protected httpClient: HttpClient
    ) {
        super(urlBase, httpClient);
        this.url = `${urlBase}/v1/parametrovalor`;
    }

    public getParametroValor = () => this.httpClient.get<ApiResult<ParametroValorEntity[]>>(this.url);
    public createOrUpdate = (record: ParametroValorEntity[]) => this.httpClient.post<ParametroValorEntity>(this.url, record);
}
