import { FocusMonitor } from '@angular/cdk/a11y';
import { HttpClient } from '@angular/common/http';
import { Component, ChangeDetectionStrategy, ElementRef, Input, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseFieldDirective } from '../base-field.directive';
import { SelectField } from './select-field';

@Component({
  selector: 'ui-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldComponent extends BaseFieldDirective implements OnInit {

  @Input() public config!: SelectField;

  constructor(
    protected fmRef: FocusMonitor,
    protected elRef: ElementRef<HTMLElement>) {
    super(fmRef, elRef);
  }

  public atualizaControle = (event: any) => {
    if(this.config!.onValueSelectedFunc){
        this.config!.onValueSelectedFunc(event.value);
    }
  };
}
