import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ObterPendentes, Remover } from './notificacao-store/notificacao.actions';
import { NotificacaoSelectors } from './notificacao-store/notificacao.selectors';
import { NotificacaoModel } from './notificacao.model';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  @Select(NotificacaoSelectors.notificacoes) public notificacoes$: Observable<NotificacaoModel[]>;

  constructor() {
    this.obterPendentes();
  }

  @Dispatch()
  public obterPendentes = () => new ObterPendentes();

  @Dispatch()
  public remover = (id: string) => new Remover(id);
}
