import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageUtil, LoginService } from 'ui-shared';

@Injectable({
  providedIn: 'root'
})
export class TreinamentoAuthGuard implements CanActivate {
  constructor(
    @Inject(Router) private router: Router,
    @Inject(LoginService) private loginService: LoginService
  ) { }

  canActivate = () => this.loginService.estaLogado() || this.router.navigateByUrl('/login')
}

