import {API_URL, CrudService} from 'ui-shared';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AulaMaterialEntity} from "@treinamento/models";

@Injectable({ providedIn: 'any' })
export class AulaMaterialDataService extends CrudService<AulaMaterialEntity> {

    protected url: string;
    urlOriginal: string;

    constructor(
        @Inject(API_URL) urlBase: string,
        @Inject(HttpClient) protected httpClient: HttpClient
    ) {
        super(urlBase, httpClient);
        this.urlOriginal = `${urlBase}/v1/aula/<id>/material`;
    }

    public withParentId(parentId: string) {
        this.url = this.urlOriginal.replace('<id>', parentId);
        return this;
    }
}
