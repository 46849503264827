import {HttpResponse} from "@angular/common/http";

export function toFormData(payload: any) {
    return Object.keys(payload).reduce((f, x) => {
        f.append(x, payload[x]);
        return f;
    }, new FormData());
}

export function openFileResponse(response: HttpResponse<Blob>, download: boolean = false){
    const contentDisposition = response.headers.get('content-disposition');

    const filename = contentDisposition.split(';')[1]
        .split('filename')[1]
        .split('=')[1]
        .trim();
    const file = new File([response.body], filename, {type: response.body.type});
    const fileUrl = (window.webkitURL || window.URL).createObjectURL(file);

    if(file.type == 'application/pdf'){
        let newWindow = window.open();
        newWindow.document.title = filename;
        newWindow.location.href = fileUrl;
    }else{
        const a = document.createElement('a');
        a.href = fileUrl;
        a.target = '_blank';
        a.type = response.body.type;

        if(download) {
            a.download = filename;
        }

        a.click();
        a.remove();
    }

    (window.webkitURL || window.URL).revokeObjectURL(fileUrl);
}