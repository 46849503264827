<mat-form-field appearance="outline" [formGroup]="formGroup" style="margin-bottom: -1.25em; width: 100%;">
  <mat-label>{{config.label}}</mat-label>
  <div *ngIf="config.useValueSelected">
    <div *ngIf="!config.useAsync">
      <mat-select *ngFor="let selected of config.valueSelected | async" [formControl]="controle"  (selectionChange)="atualizaControle($event)" [(ngModel)]="selected.id">
        <mat-option>Selecione</mat-option>
        <mat-option *ngFor="let option of config.values" [value]="option.value">{{option.text}}</mat-option>
      </mat-select>
    </div>
    <div *ngIf="config.useAsync">
      <mat-select *ngFor="let selected of config.valueSelected | async" [formControl]="controle"  (selectionChange)="atualizaControle($event)" [(ngModel)]="selected.id">
        <mat-option>Selecione</mat-option>
        <mat-option *ngFor="let option of config.valuesAsync | async" [value]="option.value">{{option.text}}</mat-option>
      </mat-select>
    </div>
  </div>
  <div *ngIf="!config.useValueSelected">
    <mat-select [formControl]="controle" *ngIf="!config.useAsync" (selectionChange)="atualizaControle($event)">
      <mat-option>Selecione</mat-option>
      <mat-option *ngFor="let option of config.values" [value]="option.value">{{option.text}}</mat-option>
    </mat-select>
    <mat-select [formControl]="controle" *ngIf="config.useAsync" (selectionChange)="atualizaControle($event)">
      <mat-option>Selecione</mat-option>
      <mat-option *ngFor="let option of config.valuesAsync | async" [value]="option.value">{{option.text}}</mat-option>
    </mat-select>
  </div>
</mat-form-field>